import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiSelectComp } from "./multi-select";
import { lookup, LookupParams, LookupResult } from "../../services/data-service";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import { IFlowState } from "../../pages/flows/flows-transfers";
import { TInfoState } from "../../App";
import { InfoTooltip } from "../helper-components";

export type TransferMultiSelectCompProps = {
    value: string[];
    orgType: LookupParams['goal'];
    placeholder: string;
    label: string;
    onChange: (v: string[]) => void
    searchNames: any
  }

type TSearchState = {
    length: number
    hits: string[]
}

export const TransferMultiSelectComp = ({ value, onChange, searchNames, orgType, placeholder, label }: TransferMultiSelectCompProps) => {
  const { t } = useTranslation()
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [search, setSearch] = useState<TSearchState>({length: 0, hits: [] });
  const { query } = useSelector<AppState,IFlowState>((state) => state.flow);
  const { useHalfyears } = useSelector<AppState,TInfoState>((state) => state.info);

  const lookupRestricted = useMemo(() =>
    [...(query.mediaGroups || []), ...(query.media || []), ...(query.mediaOwner || [])].length > 0,
  [query.mediaGroups, query.media, query.mediaOwner])

  const populateList = (orgType: LookupParams['goal']) => (name: string) => {
    if (loadingOptions) return;
    if ((name.length > search.length) && name.length === 3) {
      setLoadingOptions(true)
      lookup({
                search: name,
                goal: orgType,
                from: query.from || 0,
                to: query.to || 0,
                slot: useHalfyears ? 'halfyears' : 'quarters',
                transferType: Array.isArray(query.pType) ? query.pType : [query.pType],
                mediaGroup: query.mediaGroups || [],
                media: query.media || [],
                mediaOwner: query.mediaOwner || [],
                mediaGroupType: query.mediaGroupType || "",
              })
      .then(
        results => setSearch( { length: name.length, hits: results.map((r: LookupResult) => r.name) } )
      )
        .finally(() => setLoadingOptions(false))
      return ['A', 'B', 'C'];
    }
    if ((name.length < search.length) && name.length < 3) {
      setSearch({ length: name.length, hits: [] })
      return [];
    }
  }

    return (
      <>
      <MultiSelectComp options={search.hits}
          value={value} placeholder={placeholder} label={label}
          onChange={(newValue) => onChange(newValue)}
          //helpText={lookupRestricted ? <span>{t("restricted_by_orgs")} <InfoTooltip text='restricted_by_orgs_explanation' /></span> : ""}
          getOptionLabel={(v) => orgType==="media" ? v['name'] : v}
          helperText={lookupRestricted ? <span>{t("restricted_by_media")} <InfoTooltip text='restricted_by_media_explanation' /></span> : ""}
        onSearch={(text) => populateList(orgType)(text)} noOptionsText={t('Start typing')}/>
      </>
    )
}
