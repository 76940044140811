import { TextField, Autocomplete, Chip, Checkbox, Tooltip } from "@mui/material"
import React, { useEffect, useState } from "react";
import Config from '../../config/settings';

export type MultiSelectCompProps<T> = {
  options: T[];
  value: T[];
  placeholder: string;
  label: string;
  noOptionsText?: string;
  onChange: (v: T[]) => void
  onSearch?: (v: string) => void;
  filterCriterion?: (v: T) => boolean;
  getOptionLabel: (v: T) => string;
  getOptionKey?: (v: T) => string;
  helperText?: React.ReactNode;
}

export const MultiSelectComp = <T,>({ options, value, placeholder, label,
    noOptionsText, onChange, onSearch, filterCriterion, helperText,
    getOptionLabel }: MultiSelectCompProps<T>) => {
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [selectedItems, setSelectedItems] = useState(value);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onFilterOptions = (e: any) => {
    const text = e.target.value;
    if (onSearch) {
      onSearch(text);
    } else {
      //f => f.toLowerCase().includes(text.toLowerCase())
      const filtered = options.filter(filterCriterion ? filterCriterion : (f: T) => true);
      setFilteredOptions(filtered);
    }
  }

  useEffect(() => {
    if (onSearch) {
      setFilteredOptions(options);
    }
  }, [options, onSearch])
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      noOptionsText={noOptionsText}
      options={filteredOptions}
      value={selectedItems}
      fullWidth={true}
      onChange={(event, newValue) => {
        setSelectedItems(newValue);
        if (!isDropdownOpen) onChange(newValue)
      }}
      onOpen={() => setIsDropdownOpen(true)}
      onClose={() => {
        setIsDropdownOpen(false);
        onChange(selectedItems)
      }}

      //getOptionKey={getOptionKey || ((v: T) => v as string)}
      renderTags={renderAutocompleteTags}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {getOptionLabel(option)}
        </li>
        // <MenuItem
        //     key={option}
        //     value={option}
        //     //sx={{ justifyContent: "space-between" }}
        //     {...props}
        // >

        //   <Checkbox checked={selected} size="small" sx={{padding: '5px'}} />
        //   <ListItemText primary={option}  />

        // </MenuItem>
      )}
      renderInput={
        (params) => {
      console.log(params)
      return (<TextField {...params}
        onChange={(e) => onFilterOptions(e)}
        InputLabelProps={params.InputLabelProps as any}
        InputProps={{ ...params.InputProps }}
        placeholder={placeholder} variant={Config.input.labelVariant}
        helperText={helperText}
        label={label} />

      )}
    }
    />)
}

export const renderAutocompleteTags = (value, getTagProps) =>
  value.map((option, index) => (
    <Chip
      sx={{ height: '30px' }}
      size="small"
      label={<Tooltip title={option} arrow>{option}</Tooltip>}
      color="primary"
      {...getTagProps({ index })}
    />
  ))
