import { useMemo, useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { countEntities, createMediaAndOwners, getMedialist } from '../services/data-service';
import { ModalLoader, ShowError } from '../components/helper-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component-with-filter';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import { infoSlice } from '../App';
import { getEventsFrom } from '../services/data-service';
import { getTranslatedPaginationOptions } from '../helpers/helpers';

export interface IMediaListDocument {
  _id: string;
  mediaID: number;
  mediaName: string;
  category: string;
  subCategory: string;
  mediaOwner: string;
  mediaOwnerID: number;
}


interface MediaQuery {
  media: string;
  mediaOwner: string;
  mediaID: string;
  mediaOwnerID: string;
  category: string;
  subCategory: string;
}

interface IMediaState {
  mediaList: IMediaListDocument[];
  error?: string;
  pending: boolean;
  //message?: string;
  query: MediaQuery;
  page: number;
  size: number;
  count: number;
  needsUpdate: boolean;
}

const initialState: IMediaState = {
  mediaList: [],
  pending: false,
  needsUpdate: true,
  //message: "",
  page: 1,
  size: 10,
  count: 0,
  query: {} as MediaQuery
}

export const mediaReducers = {
  setPending: (state: IMediaState, action: PayloadAction<boolean>) => {
    state.pending = action.payload;
  },
  setError: (state: IMediaState, action: PayloadAction<string>) => {
    state.error = action.payload;
  },
  // setMessage: (state: IMediaState, action: PayloadAction<string>) => {
  //   state.message = action.payload;
  // },
  setNeedsUpdate: (state: IMediaState, action: PayloadAction<void>) => ({ ...state, needsUpdate: true }),
  setPage: (state: IMediaState, action: PayloadAction<number>) => ({ ...state, page: action.payload, needsUpdate: true }),
  setSize: (state: IMediaState, action: PayloadAction<number>) => ({ ...state, size: action.payload, needsUpdate: true }),
  setCount: (state: IMediaState, action: PayloadAction<number>) => ({ ...state, count: action.payload }),
  setQuery: (state: IMediaState, action: PayloadAction<MediaQuery>) => {
    state.query = action.payload
  },
  setMediaList: (state: IMediaState, action: PayloadAction<IMediaListDocument[]>) => {
    state.mediaList = action.payload;
    state.needsUpdate = false;
  },
}

export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: mediaReducers,
});

const { setPending, setError, setMediaList, setNeedsUpdate,
  setPage, setCount, setSize, setQuery } = mediaSlice.actions;

const { setMessage } = infoSlice.actions


export const MediaList = () => {
  const { pending, error, mediaList, page, query,
    count, size, needsUpdate } = useSelector((state: any) => state.media);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [sortBy, setSortBy] = useState<string | null>(null)
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('asc')
  const columns = useMemo(() => [
    {
      name: t('Media ID'),
      selector: row => row['mediaID'],
      sortable: true,
      filterable: true,
      width: '12%'
    },
    {
      name: t('Media'),
      selector: row => row['mediaName'],
      sortable: true,
      filterable: true,
      wrap: true,
    },
    {
      name: t('Media Owner'),
      selector: row => row['mediaOwner'],
      sortable: true,
      filterable: true,
      wrap: true,
    },
    {
      name: t('Media Owner ID'),
      selector: row => row['mediaOwnerID'],
      sortable: true,
      filterable: true,
      width: '12%'
    },
    {
      name: t('category'),
      selector: row => row['category'],
      sortable: true,
      filterable: true,
      filterValues: ['Fernsehen', 'Hörfunk', 'Print', 'Online', 'Out of Home'],
    },
    {
      name: t('Subcategory'),
      selector: row => row['subCategory'],
      sortable: true,
      filterable: true
    },
  ], [t, i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  const namesToColumns = useMemo(() => ({
    [t('Media ID')]: "mediaID",
    [t('Media')]: "mediaName",
    [t('Media Owner')]: "mediaOwner",
    [t('Media Owner ID')]: "mediaOwnerID",
    [t('Category')]: "category",
    [t('Subcategory')]: "subCategory",
  }), [t, i18n.language]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mediaList.length === 0 || needsUpdate) {
      dispatch(setPending(true))
      Promise.all([countEntities('/medialist/count', query),
      getMedialist(page, size, sortBy ?? '', sortOrder, query)])
        .then(([count, media]) => {
          dispatch(setCount(count))
          dispatch(setMediaList(media))
        }).catch(err => {
          err.message ? setError(err.message) : setError(err)
        }).finally(() => dispatch(setPending(false)))
    }
  }, [needsUpdate, dispatch, page, mediaList.length, query, size, sortBy, sortOrder])


  const createMediaAndOwnersAction = async () => {
    try {
      dispatch(setError(""));
      dispatch(setPending(true));
      const mediaResp = await createMediaAndOwners()
      dispatch(setMessage(mediaResp['messages'].join(', ')))
    } catch (error: any) {
      dispatch(setError(error ? (error['message'] ? error.message : error) : "Error while updating media and owners"));
    } finally {
      dispatch(setPending(false));
    }
  }

  const updateMediaListAndMore = async () => {
    try {
      dispatch(setError(""));
      dispatch(setPending(true));
      await getEventsFrom("/api/medialist/update", msg => dispatch(setMessage(msg)));
    } catch (error: any) {
      dispatch(setError(error ? (error['message'] ? error.message : error) : "Error while updating media list and more"));
    } finally {
      dispatch(setPending(false));
    }
  }

  return (
    <div>
      <ModalLoader isPending={pending} />
      <ShowError error={error} onClose={() => setError("")} />
      <h1>{t('Media List')}</h1>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <Button variant="contained" onClick={createMediaAndOwnersAction} startIcon={<ChecklistIcon />}
          color="primary" >{t('Update Media and Organisation List')}</Button>
        <Button variant="contained" onClick={updateMediaListAndMore} startIcon={<DynamicFeedIcon />}
          color="primary" >{t('Update RTR Medialist, MediaOnwers and Displayname')}</Button>
      </Box>
      <Card>
        <CardHeader title={t("Media List")} />
        <CardContent className='no-padding'>
          <DataTable
            columns={columns}
            data={mediaList}
            pagination={true}
            paginationServer={true}
            onChangePage={e => dispatch(setPage(e))}
            onChangeRowsPerPage={e => dispatch(setSize(e))}
            paginationTotalRows={count}
            progressPending={pending}
            paginationComponentOptions={getTranslatedPaginationOptions(t)}
            onFilter={e => {
              dispatch(setQuery({
                ...Object.values(e).reduce((acc, { column, value }) =>
                  ({ ...acc, [namesToColumns[column.name?.toString() ?? "test"]]: value }), {} as MediaQuery)
              }))
              dispatch(setNeedsUpdate())
            }}
            sortServer={true}
            onSort={(column, sortDirection) => {
              setSortOrder(sortDirection)
              setSortBy(namesToColumns[column.name?.toString() ?? ""])
              dispatch(setNeedsUpdate())
              //console.log(column, sortDirection)
            }}
          />
        </CardContent>
      </Card>
    </div>

  )
}
