import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { Tooltip } from "@mui/material";
import { Help, InfoTooltip } from "../helper-components";
import { SwitchButton } from "../switch-button/switchButton";
import { lookup, LookupResult } from "../../services/data-service";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import { IFlowState } from "../../pages/flows/flows-transfers";
import { TInfoState } from "../../App";

export type MediaOwnerMultiSelectCompProps = {
    value: LookupResult[];
    onChange: (v: LookupResult[]) => void
    pType: 2 | number[] | 4 | 31
}

type TSearchState = {
    length: number
    hits: LookupResult[]
    raw: LookupResult[]
}


const CreateChipContent = ({ mediaOrOwner }: { mediaOrOwner: LookupResult }) => {
    return <span>
        <Tooltip title={mediaOrOwner.name} arrow>
            <span>{mediaOrOwner.name}</span>
        </Tooltip>
    </span>
}

const renderAutocompleteTags = (value, getTagProps) => {
    return value.map((option, index) => (
        <Chip
            sx={{ height: '30px' }}
            size="small"
            label={<CreateChipContent mediaOrOwner={option} />}
            icon={option.type === "media" ? <PermMediaIcon /> : <ApartmentIcon />}
            color={option.type === "media" ? "primary" : "secondary"}
            {...getTagProps({ index })}
        />
    ))
}

const restrictionLabels = {
    "media": "Media",
    "mediaOwner": "Media Owner",
    "both": "Media and Media Owner"
}



export const MediaOwnerMultiSelectComp = ({ value, onChange, pType }: MediaOwnerMultiSelectCompProps) => {
    const { t } = useTranslation()
    const [selectionMode, setSelectionMode] = useState<"media" | "mediaOwner" | "both">("both");
    const [mediaRestrictionEnabled, setMediaRestrictionEnabled] = useState(false);
    const [restrictToMedia, setRestrictToMedia] = useState(false);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [search, setSearch] = useState<TSearchState>({ length: 0, hits: [], raw: [] });
    const [selectedMediaOwners, setSelectedMediaOwners] = useState<LookupResult[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { query } = useSelector<AppState,IFlowState>((state) => state.flow);
    const { useHalfyears } = useSelector<AppState,TInfoState>((state) => state.info);


    useEffect(() => {
        if (!pType) return;
        const pTypes = Array.isArray(pType) ? pType : [pType];
        if (pTypes.length > 1) {
            setSelectionMode("both")
            if (mediaRestrictionEnabled) setMediaRestrictionEnabled(false)
        } else if (pTypes[0].toString() === "4") {
            setSelectionMode("mediaOwner")
            if (mediaRestrictionEnabled) setMediaRestrictionEnabled(false)
        } else if (pTypes[0].toString() === "2") {
            setSelectionMode(restrictToMedia ? "media" : "both")
            if (!mediaRestrictionEnabled) setMediaRestrictionEnabled(true)
        }
    }, [pType, restrictToMedia]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedMediaOwners(value)
    }, [value])

    const lookupRestricted = useMemo(() =>
      [...(query.orgGroups || []), ...(query.organisations || [])].length > 0,
    [query.orgGroups, query.organisations])


    const populateList = (name: string) => {
        if (loadingOptions) return;
        if (name.length >= 3) {
            if (name.length === 3 && name.length > search.length) {
                setLoadingOptions(true)
                lookup({
                          search: name,
                          goal: selectionMode !== "both" ? selectionMode : "mediaAndOwner",
                          from: query.from || 0,
                          to: query.to || 0,
                          slot: useHalfyears ? 'halfyears' : 'quarters',
                          transferType: Array.isArray(query.pType) ? query.pType : [query.pType],
                          orgGroup: query.orgGroups || [],
                          organisation: query.organisations || [],
                          orgGroupType: query.orgGroupType || "",
                       })
                //searchMediaOwners(name, selectionMode !== "both" ? selectionMode : undefined)
                    .then(results => results.sort((a, b) => a.name.localeCompare(b.name)))
                    .then(
                        names => setSearch({ length: name.length, hits: names, raw: names })
                    )
                    .finally(() => setLoadingOptions(false))
            } else {
                setSearch({
                    length: name.length,
                    hits: search.raw.filter(v => v.name.toLowerCase().includes(name.toLowerCase())),
                    raw: search.raw
                })
            }
            return ['A', 'B', 'C'];
        } else if (name.length < search.length) {
            setSearch({ length: name.length, hits: [], raw: [] })
            return [];
        }
    }

    return <>
        <SwitchButton
            id="mediaOwnerRestriction"
            simple={true}
            selValue={restrictToMedia}
            disabled={!mediaRestrictionEnabled || isDropdownOpen}
            onlabel={
                <span>
                    {t("Restrict to Media only")} <Help text={"media_only__help"} />
                </span>
            }
            offlabel={
                <span>
                    {t("Restrict to Media only")} <Help text={"media_only__help"} />
                </span>
            }

            onChange={() => setRestrictToMedia(!restrictToMedia)}
        ></SwitchButton>

        <Autocomplete
            multiple
            disableCloseOnSelect={true}
            noOptionsText={t("Start typing")}
            options={search.hits}
            value={selectedMediaOwners}
            fullWidth={true}
            getOptionLabel={(v) => v.name}
            renderInput={(params) => <TextField {...params}
                InputLabelProps={params.InputLabelProps as any}
                helperText={lookupRestricted ? <span>{t("restricted_by_orgs")} <InfoTooltip text='restricted_by_orgs_explanation' /></span> : ""}
                label={<span>{t(restrictionLabels[selectionMode])}</span>}/>}
            getOptionKey={(v) => `${v.name}-${v.type}`}
            onChange={(_, newValue) => {
                setSelectedMediaOwners(newValue);
                if (!isDropdownOpen) onChange(newValue)
            }}
            onInputChange={
                (_, name, reason) => {
                    if (reason === "input") {
                        populateList(name)
                    }
                }
            }
            onOpen={() => setIsDropdownOpen(true)}
            onClose={() => {
                setIsDropdownOpen(false);
                onChange(selectedMediaOwners)
                setSearch({ length: 0, hits: [], raw: [] })
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name && option.type === value.type}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    <Tooltip title={t(option.type === "media" ? "medium" : option.type)} arrow >
                        {option.type === "media" ?
                            <PermMediaIcon fontSize="small" color="primary" /> :
                            <ApartmentIcon fontSize="small" color="secondary" />}
                    </Tooltip>
                    &nbsp;
                    {option.name}
                </li>
            )}
            renderTags={renderAutocompleteTags}
        />
    </>

}
